import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { EmailSettingsFormData } from 'app/pages/RestaurantSettings/Notifications/EmailSettings';

export const getNotificationSMSSettingsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.SMS,
    },
    true,
  );
};

export const getNotificationEmailSettingsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.EMAIL,
    },
    true,
  );
};

export const updateNotificationEmailSettingsCall = async (data: EmailSettingsFormData) => {
  return await asyncRequest(
    {
      method: 'PUT',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.EMAIL,
      data,
    },
    true,
  );
};
